export function fullscreenNav(): void {
  const mobileMenuTrigger = document.querySelector('.mobile-nav-trigger') as HTMLElement
  const mobileNav = document.querySelector('.mobile-nav') as HTMLElement
  const bookButton = document.querySelector('.book-button') as HTMLElement
  const backgroundVideo = mobileNav.querySelector('.background-video video') as HTMLVideoElement

  if (mobileMenuTrigger) {
    mobileMenuTrigger.addEventListener('click', () => {
      if (mobileMenuTrigger.dataset.state === 'active') {
        mobileMenuTrigger.dataset.state = 'inactive'
        mobileNav.dataset.state = 'inactive'
        bookButton.classList.remove('mobile-hidden')
      } else {
        mobileMenuTrigger.dataset.state = 'active'
        mobileNav.dataset.state = 'active'
        bookButton.classList.add('mobile-hidden')
      }
    })
  }

  if (mobileMenuTrigger && backgroundVideo) {
    mobileMenuTrigger.addEventListener('mouseover', () => {
      if (window.innerWidth > 768 && backgroundVideo.dataset.src && !backgroundVideo.src) {
        backgroundVideo.src = backgroundVideo.dataset.src
      }
    })
  }

  const venuesButton = mobileNav.querySelector('[data-link-type="venues"]') as HTMLElement
  const venueLocationsNav = mobileNav.querySelector('.venue-locations') as HTMLElement
  const whatsOnButton = mobileNav.querySelector('[data-link-type="whats-on"]') as HTMLElement
  const whatsOnNav = mobileNav.querySelector('.whats-on-nav') as HTMLElement
  const featuredNav = mobileNav.querySelector('.featured') as HTMLElement
  const whatsOnSubNav = mobileNav.querySelector('.whats-on-sub-nav') as HTMLElement
  const venuesSubNav = mobileNav.querySelector('.venues-sub-nav') as HTMLElement

  venuesButton.addEventListener('click', () => {
    venuesButton.classList.toggle('active')
    whatsOnButton.classList.remove('active')

    if (venueLocationsNav.classList.contains('hidden')) {
      featuredNav.classList.add('hidden')
      venueLocationsNav.classList.remove('hidden')
      whatsOnNav.classList.add('hidden')
    } else {
      venueLocationsNav.classList.add('hidden')
      featuredNav.classList.remove('hidden')
    }

    if (venuesSubNav.classList.contains('active')) {
      venuesSubNav.classList.remove('active')
    } else {
      venuesSubNav.classList.add('active')
    }
    whatsOnSubNav.classList.remove('active')
  })

  whatsOnButton.addEventListener('click', () => {
    whatsOnButton.classList.toggle('active')
    venuesButton.classList.remove('active')

    if (whatsOnNav.classList.contains('hidden')) {
      whatsOnNav.classList.remove('hidden')
      featuredNav.classList.add('hidden')
      venueLocationsNav.classList.add('hidden')
    } else {
      whatsOnNav.classList.add('hidden')
      featuredNav.classList.remove('hidden')
      venueLocationsNav.classList.add('hidden')
    }

    if (whatsOnSubNav.classList.contains('active')) {
      whatsOnSubNav.classList.remove('active')
    } else {
      whatsOnSubNav.classList.add('active')
    }

    venuesSubNav.classList.remove('active')
  })
}
